import INC_BASE_API from 'apis/incentivio-api';
import { getResponseErrorMessage } from 'apis/incentivio-api.util';
import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/**
 * Remove when one-time-use-links-user-interaction is launched
 */
export const useVerifyEmailRequest = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const code = useURLSearchParams().get('code');

  useEffect(() => {
    const verifyEmailRequest = async () => {
      setLoading(true);
      try {
        await INC_BASE_API.get(`verifyemail?code=${code}`);
      } catch (error) {
        toast.error(getResponseErrorMessage(error));
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    if (!!code) verifyEmailRequest();
    else setError(true);
  }, [code, setLoading]);

  return { loading, error };
};

/**
 * Rename to useVerifyEmailRequest when one-time-use-links-user-interaction is launched.
 */
export const useGatedVerifyEmailRequest = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const code = useURLSearchParams().get('code');

  const verifyEmailRequest = useCallback(async () => {
    setLoading(true);
    try {
      await INC_BASE_API.get(`verifyemail?code=${code}`);
      setSuccess(true);
    } catch (error) {
      toast.error(getResponseErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }, [code]);

  useEffect(() => {
    if (!code) setError(true);
  }, [code]);

  return { error, success, loading, verifyEmailRequest };
};
