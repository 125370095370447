import React from 'react';
import * as S from '../reset-password/reset-password.styles';
import { useTranslation } from 'react-i18next';

import Spinner from 'components/spinner/spinner.component';
import { Button, Stack, Typography } from '@mui/material';
import { useGatedOptOutEmail, useOptOutEmail } from './unsubscribe.hooks';
import { usePageTitle } from 'hooks/usePageTitle.hooks';
import { Helmet } from 'react-helmet';
import { useGate } from 'statsig-react';

/**
 * Remove when one-time-use-links-user-interaction is launched
 */
const Unsubscribe = () => {
  const { t } = useTranslation();
  const title = usePageTitle(t('head.title.unsubscribe'));
  const { value: gateValue, isLoading: isGateLoading } = useGate(
    'one-time-use-links-user-interaction',
  );

  return (
    <S.Container data-testid={'Unsubscribe.Container'}>
      <Helmet title={title} />
      {isGateLoading && <Spinner />}
      {gateValue ? <GatedUnsubscribe /> : <UngatedUnsubscribe />}
    </S.Container>
  );
};

/**
 * Remove when one-time-use-links-user-interaction is launched
 */
const UngatedUnsubscribe = () => {
  const { t } = useTranslation();
  const { loading, error, errorTitle, errorDesc } = useOptOutEmail();

  return (
    <>
      {loading && <Spinner />}
      <S.ContentContainer elevation={2}>
        <S.ContentHeader
          component="h1"
          variant="h5"
          aria-label={t('optOut.title')}
          data-testid={'Unsubscribe.Title'}
        >
          {error ? errorTitle : t('optOut.title')}
        </S.ContentHeader>
        <S.ContentBody>
          <Typography
            component="h2"
            aria-label={t('optOut.description')}
            data-testid={'Unsubscribe.Description'}
          >
            {error ? errorDesc : t('optOut.description')}
          </Typography>
        </S.ContentBody>
      </S.ContentContainer>
    </>
  );
};

/**
 * Rename to Unsubscribe when one-time-use-links-user-interaction is launched.
 */
const GatedUnsubscribe = () => {
  const { t } = useTranslation();
  const { success, loading, optOutEmail } = useGatedOptOutEmail();

  return (
    <S.ContentContainer elevation={2}>
      <S.ContentHeader
        component="h1"
        variant="h5"
        data-testid={'Unsubscribe.Title'}
      >
        {t('unsubscribe.title')}
      </S.ContentHeader>
      <S.ContentBody>
        {success ? (
          <Typography data-testid={'Unsubscribe.Description'}>
            {t('optOut.description')}
          </Typography>
        ) : (
          <Stack spacing={1}>
            <Typography
              data-testid={'Unsubscribe.Description'}
              textAlign="center"
            >
              {t('unsubscribe.description')}
            </Typography>
            <Button
              variant="contained"
              onClick={optOutEmail}
              disabled={loading}
              data-testid="Unsubscribe.Button"
            >
              {t('unsubscribe.button.label')}
            </Button>
          </Stack>
        )}
      </S.ContentBody>
    </S.ContentContainer>
  );
};

export default Unsubscribe;
