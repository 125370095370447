import ComingSoon from 'components/coming-soon/coming-soon.component';
import ErrorBoundary from 'components/error-boundary/error-boundary.component';
import GoogleSocialSignIn from 'components/google-social-sign-in/google-social-sign-in.component';
import HiddenMenuErrorProvider from 'components/hidden-menu-error-dialog/hidden-menu-error.provider';
import MenuDialog from 'components/menu-dialog/menu-dialog.component';
import PromptSmsOpt from 'components/prompt-sms-opt/prompt-sms-opt.component';
import Spinner from 'components/spinner/spinner.component';
import StatsigWrapper from 'components/statsig/statsig-wrapper.component';
import { useInitAppleID } from 'hooks/appleSignIn.hooks';
import { useOnRouteChange } from 'hooks/history';
import CheckoutStatus from 'pages/checkout/checkout-status/checkout-status.component';
import GiftcardPage from 'pages/giftcard/giftcard.component';
import ImHere from 'pages/imhere/imhere.component';
import SmsSignup from 'pages/sms-signup/sms-signup.component';
import StorePage from 'pages/store/store.component';
import Unsubscribe from 'pages/unsubscribe/unsubscribe.component';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Theme from '../../Theme';
import Account from '../../components/account/account.component';
import Header from '../../components/header/header.component';
import {
  selectIsGoogleMapsLoaded,
  selectIsWOV2Enabled,
} from '../../redux/config/config.selectors';
import CheckoutPage from '../checkout/checkout.component';
import LocationsPage from '../locations/locations.component';
import SignupPage from '../signup/signup.component';
import ThankYouPage from '../thank-you/thank-you.component';
import AuthRoutes from './auth-routes/auth-routes.component';
import { useAuth } from './client.hooks';
import { AppContainer, GlobalStyles } from './client.styles';
import Heap from './heap';

// eslint-disable-next-line
export default () => {
  const isWOV2Enabled = useSelector(selectIsWOV2Enabled);
  const googleMapsLoaded = useSelector(selectIsGoogleMapsLoaded);
  const { userLoading } = useAuth();

  useOnRouteChange();
  useInitAppleID();

  return isWOV2Enabled ? (
    <StatsigWrapper>
      <Heap>
        <Theme>
          <GlobalStyles />
          <ErrorBoundary name="app">
            <HiddenMenuErrorProvider>
              <GoogleSocialSignIn>
                <Header />
                {userLoading ? (
                  <Spinner />
                ) : (
                  <AppContainer
                    data-testid="Client.AppContainer"
                    id="main-content"
                  >
                    <ErrorBoundary name="client">
                      {googleMapsLoaded && (
                        <>
                          <Switch>
                            <Route exact path={'/'} component={LocationsPage} />
                            <Route
                              path={[
                                `/settings`,
                                `/inbox`,
                                `/order-history`,
                                `/my-cards`,
                              ]}
                              component={Account}
                            ></Route>
                            <Route
                              path="/emailoptout"
                              component={Unsubscribe}
                            />
                            <Route path="/imhere/:orderId" component={ImHere} />
                            <Route
                              path="/locations/:storeTitle"
                              component={StorePage}
                            />
                            <Route
                              exact
                              path={`/checkout`}
                              component={CheckoutPage}
                            />
                            <Route path={`/signup`} component={SignupPage} />
                            <Route
                              exact
                              path={`/checkout/processing/:orderId`}
                              component={CheckoutStatus}
                            />
                            <Route
                              exact
                              path={`/checkout/thankyou`}
                              component={ThankYouPage}
                            />
                            <Route path="/mobilesignup" component={SmsSignup} />
                            <Route path="/giftcard" component={GiftcardPage} />
                            <Redirect exact path={`/locations`} to={'/'} />
                            <AuthRoutes />
                            <Redirect to={'/'} />
                          </Switch>
                          <MenuDialog />
                          <PromptSmsOpt />
                        </>
                      )}
                    </ErrorBoundary>
                  </AppContainer>
                )}
              </GoogleSocialSignIn>
            </HiddenMenuErrorProvider>
          </ErrorBoundary>
        </Theme>
      </Heap>
    </StatsigWrapper>
  ) : (
    <StatsigWrapper>
      <Theme>
        <GlobalStyles />
        <GoogleSocialSignIn>
          <Switch>
            <Route exact path="/" component={ComingSoon} />
            <Route path="/emailoptout" component={Unsubscribe} />
            <Route path="/imhere/:orderId" component={ImHere} />
            <AuthRoutes />
            <Redirect to={'/'} />
          </Switch>
        </GoogleSocialSignIn>
      </Theme>
    </StatsigWrapper>
  );
};
