import Spinner from 'components/spinner/spinner.component';
import { useTranslation } from 'react-i18next';
import {
  useGatedVerifyEmailRequest,
  useVerifyEmailRequest,
} from './useVerifyEmailRequest.hooks';
import VerifyEmailErrorMessage from './verify-email-error-message/verify-email-error-message.component';
import VerifyEmailHeader from './verify-email-header/verify-email-header.component';
import VerifyEmailSuccessMessage from './verify-email-success-message/verify-email-success-message.component';
import * as S from './verify-email.styles';
import { useGate } from 'statsig-react';
import { Button, Stack, Typography } from '@mui/material';

/**
 * Remove when one-time-use-links-user-interaction is launched
 */
const VerifyEmail = () => {
  const { value: gateValue, isLoading: isGateLoading } = useGate(
    'one-time-use-links-user-interaction',
  );

  return (
    <S.Container data-testid="VerifyEmail.Container">
      {isGateLoading && <Spinner />}

      {gateValue ? <GatedVerifyEmail /> : <UngatedVerifyEmail />}
    </S.Container>
  );
};

/**
 * Remove when one-time-use-links-user-interaction is launched
 */
const UngatedVerifyEmail = () => {
  const { t } = useTranslation();
  const { loading, error } = useVerifyEmailRequest();

  return (
    <>
      {loading && <Spinner />}
      <S.ContentContainer elevation={2}>
        {!error && <VerifyEmailHeader title={t('verifyEmail.header')} />}
        <S.ContentBody>
          {error ? <VerifyEmailErrorMessage /> : <VerifyEmailSuccessMessage />}
        </S.ContentBody>
      </S.ContentContainer>
    </>
  );
};

/**
 * Rename to VerifyEmail when one-time-use-links-user-interaction is launched.
 */
const GatedVerifyEmail = () => {
  const { t } = useTranslation();
  const { loading, error, success, verifyEmailRequest } =
    useGatedVerifyEmailRequest();

  return (
    <S.ContentContainer elevation={2}>
      {!success && !error && (
        <VerifyEmailHeader title={t('verifyEmailGated.title')} />
      )}
      {success && <VerifyEmailHeader title={t('verifyEmail.header')} />}
      {error && <VerifyEmailHeader title={t('verifyEmailGated.errorTitle')} />}
      <S.ContentBody>
        {!success && !error && (
          <Stack spacing={1}>
            <Typography>{t('verifyEmailGated.description')}</Typography>
            <Button
              variant="contained"
              onClick={verifyEmailRequest}
              disabled={loading}
              data-testid="VerifyEmail.Button"
            >
              {t('verifyEmailGated.button.label')}
            </Button>
          </Stack>
        )}
        {success && <VerifyEmailSuccessMessage />}
        {error && <VerifyEmailErrorMessage />}
      </S.ContentBody>
    </S.ContentContainer>
  );
};

export default VerifyEmail;
