import INC_BASE_API from 'apis/incentivio-api';
import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const useOptOutEmail = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorDesc, setErrorDesc] = useState('');
  const pref = useURLSearchParams().get('pref');
  const errorHandling = (title, description) => {
    setErrorTitle(title);
    setErrorDesc(description);
  };
  const optOutEmail = async () => {
    setLoading(true);
    try {
      await INC_BASE_API.get(`/updateemailpref?pref=${pref}`);
      setLoading(false);
    } catch (err) {
      const { status } = err?.response;
      const { error, error_description } = err?.response?.data;
      setLoading(false);
      setError(true);
      const title = error?.charAt(0).toUpperCase() + error?.slice(1);
      error && error_description
        ? errorHandling(title, error_description)
        : errorHandling(
            t(
              `optOut.${
                status === 400 ? 'errors.invalid' : 'errors.common'
              }.title`,
            ),
            t(
              `${
                status === 400
                  ? 'optOut.errors.invalid.description'
                  : 'errors.genericError'
              }`,
            ),
          );
    }
  };

  useEffect(() => {
    optOutEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, error, errorTitle, errorDesc };
};

export const useGatedOptOutEmail = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const pref = useURLSearchParams().get('pref');

  const optOutEmail = async () => {
    setLoading(true);
    try {
      await INC_BASE_API.get(`/updateemailpref?pref=${pref}`);
      setSuccess(true);
    } catch (err) {
      const { status } = err?.response;

      const errorMessageTitle = t(
        `optOut.${status === 400 ? 'errors.invalid' : 'errors.common'}.title`,
      );
      const errorMessageDesc = t(
        `${
          status === 400
            ? 'optOut.errors.invalid.description'
            : 'errors.genericError'
        }`,
      );

      toast.error(`${errorMessageTitle}: ${errorMessageDesc}`);
    } finally {
      setLoading(false);
    }
  };

  return { success, loading, optOutEmail };
};
